import './App.css';
import React, { useEffect, useMemo, useState } from 'react';
import os from './assets/opensea.png';
import twitter from './assets/twitter-.png';
import home from './assets/home-button.png';
import logo from './assets/Logo.png';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';


const ops = () => {
	window.open("https://opensea.io/collection/hime-me-please-3rd");
}

const tweet = () => {
	window.open("https://twitter.com/_hidemeplease");
}

const homeLink = () => {
	window.open("https://hidemeplease.xyz/");
}

let ABI = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			}
		],
		"name": "mintForAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_cost",
				"type": "uint256"
			}
		],
		"name": "setCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_hiddenMetadataUri",
				"type": "string"
			}
		],
		"name": "setHiddenMetadataUri",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_maxMintAmountPerTx",
				"type": "uint256"
			}
		],
		"name": "setMaxMintAmountPerTx",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setPaused",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setRevealed",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_uriPrefix",
				"type": "string"
			}
		],
		"name": "setUriPrefix",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_uriSuffix",
				"type": "string"
			}
		],
		"name": "setUriSuffix",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "hiddenMetadataUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxMintAmountPerTx",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "uriPrefix",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "uriSuffix",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_owner",
				"type": "address"
			}
		],
		"name": "walletOfOwner",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let address = "0x1cBBF5BA9362d60747F149d4c2E35f72e1a74bC3";
const maxSupply = 10000

const Home = () =>
 {

const {open} = useWeb3Modal()
const {chain} = useNetwork()
const {switchNetwork} = useSwitchNetwork()

	const {address:walletAddress} = useAccount({async onConnect(){
		handleConnect()
	}})

	const publicClient = createPublicClient({
		chain: mainnet,
		transport: http()
	  })

	const [totalSupply,settotalSupply] = useState("")
	const [statusError,setstatusError] = useState("")
	const [statusLoading,setstatusLoading] = useState("")
	const [success,setsuccess] = useState("")
	const [nftMintingAmount,setnftMintingAmount] = useState(1)
	const [_publicMintMsg,set_publicMintMsg] = useState("Mint Here")
	const [_cost,set_cost] = useState("")
	const [_maxMintAmountPerTx,set_maxMintAmountPerTx] = useState(5)
	const [_owner,set_owner] = useState("")
	const [myNFTWallet,setmyNFTWallet] = useState("")
	
	const contract = {
		address:address,
		abi:ABI
	}

	async function handleConnect(){
		if(chain.id!==1){
			switchNetwork(1)
		}
		var data = await getBalance()
		setmyNFTWallet(data.data)
	}

	function onPlus(){
		if(nftMintingAmount<5)
		setnftMintingAmount(nftMintingAmount+1)
	}

	function onMinus(){
		if(nftMintingAmount!=1){
			setnftMintingAmount(nftMintingAmount-1)
		}
	}

	const {data,refetch,isSuccess} = useContractReads({
		contracts:[
			{...contract,functionName:'totalSupply'}
			,{...contract,functionName:'maxMintAmountPerTx'},
			{...contract,functionName:'cost'},
			{...contract,functionName:'owner'}
		]
	},)

	const{refetch:getBalance} = useContractRead({
		...contract,
		functionName:'balanceOf',
		args:[walletAddress?walletAddress:'0x']
	})

	console.log(data)

	const {writeAsync} = useContractWrite({
		...contract,
		functionName:'mint',
		onError(error){
			if(error.message.includes('balance')){
				setstatusError(true)
				setstatusLoading(false)
			}
		}
	})

	async function onMint(){
		var mintCost = parseFloat(_cost)
		mintCost = (mintCost*nftMintingAmount).toString()
		try{
			setstatusLoading(true)
			var res = await writeAsync({
				args:[nftMintingAmount],
				value:parseEther(mintCost)
			})
			var result = await publicClient.waitForTransactionReceipt(res)
			if(result.status === 'success'){
				setsuccess(true)
				setstatusLoading(false)
			}
			else{
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		}
		catch(e){
			console.log(e)
			setstatusError(true)
			setstatusLoading(false)
		}
	}

	useMemo(()=>{
		if(isSuccess===true){
		settotalSupply(data[0].result)
		set_maxMintAmountPerTx(data[1].result)
		set_cost(formatEther(data[2].result))
		set_owner (data[3].result)
	}
	},[isSuccess])


		return (
			<div class="allWrap">
				<div class="light">
					<div class="cont">

						<div class="headers">

							<div class="headers2">

								<div class="logo"><img class="logoPic" onClick={homeLink} src={logo} /></div>

								<div class="right">

									<div class="icons">
										<div class="socialIcon"><img onClick={homeLink} src={home} /></div>
										<div class="socialIcon"><img onClick={ops} src={os} /></div>
										<div class="socialIcon"><img onClick={tweet} src={twitter} /></div>
									</div>

									<div class="connect2">
										<Web3Button/>
									</div>

								</div>

							</div>

						</div>

						<div class="introduction">

							<div class="in2">
								<div class="intro">
									HIDE ME PLEASE
								</div>

								<div class="intro2">
									Welcome to the home of HIDE ME PLEASE. Discover the best items in this collection.!
								</div>

								<div class="nftblockWalletConnectedALL">
									{walletAddress === undefined ? 
										<div class="walletConnect">
											
											<button onClick={()=>{open()}} class="wallet3" >MINT NOW</button>
											
										</div>
									 : 
										 null}
								</div>
							</div>

							{walletAddress === undefined ?

								(<div class="nftPicDiv">
									<img class="nftPic" src='https://cdn.discordapp.com/attachments/1050552088779825176/1132041926456119367/hmp_nft.gif' />
								</div>) : (

									(<div class="mintDiv">
										<div class="totalSupply">{totalSupply.toString()} / 10000</div>
										{_maxMintAmountPerTx == myNFTWallet ?

											(<div class="price"><div>Limit Reached!</div></div>) :
											(<div class="price"><div>Mint Price {_cost} ETH</div></div>)}

										<div class="minting_count_button">

											<div class="center">
												
													<button onClick={onMinus} class="btnfos-0-2" type="submit">-</button>
											
											</div>

											<div>
												<div class="nftminter2">{nftMintingAmount}</div>
											</div>


											<div class="center">
													<button onClick={onPlus} class="btnfos-0-2" type="submit">+</button>
												
											</div>
										</div>

										<div class="mintbuttondiv">
											{/* <form onSubmit={this.onSubmit2}> */}
												<button class="btnfos-0-3" onClick={onMint}>
													{_publicMintMsg}</button>
											{/* </form> */}

										</div>
										<div>

											{statusError ? (
												<div class="errorMessage">
													<div >Sorry, something went wrong <br /> please try again later</div>
												</div>)
												: null}

											{statusLoading ? (
												<div class="loadingContainer">
													<div class="loadingText">Minting your NFT</div>
												</div>)
												: null}

											{success ? (
												<div class="successfully">MINTING SUCCESSFUL!</div>
											)
												: null}

										</div></div>

									)
								)}
						</div>
					</div>
				</div >
			</div >
		)
	
											}
export default Home;
